function getIcon() {
  return {
    url: `data:image/svg+xml;utf-8, <svg width="22px" height="29px" viewBox="2px 0px 22px 29px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <defs> <path d="M8,0 C3.57714286,0 0,3.57714286 0,8 C0,14 8,22.8571429 8,22.8571429 C8,22.8571429 16,14 16,8 C16,3.57714286 12.4228571,0 8,0 Z M8,10.8571429 C6.42285714,10.8571429 5.14285714,9.57714286 5.14285714,8 C5.14285714,6.42285714 6.42285714,5.14285714 8,5.14285714 C9.57714286,5.14285714 10.8571429,6.42285714 10.8571429,8 C10.8571429,9.57714286 9.57714286,10.8571429 8,10.8571429 Z" id="path-1"></path> <filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="filter-2"> <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset> <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur> <feColorMatrix values="0 0 0 0 0.117647059 0 0 0 0 0.149019608 0 0 0 0 0.176470588 0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix> </filter> </defs> <g id="ic_location_on_white_24px" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="translate(5.000000, 2.000000)"> <ellipse id="Oval" fill="%23222C33" cx="8" cy="8" rx="3.42857143" ry="3.42857143"></ellipse> <g id="Shape" fill-rule="nonzero"> <use fill="black" fill-opacity="1" filter="url(%23filter-2)" xlink:href="%23path-1"></use> <use fill="%23ffffff" fill-rule="evenodd" xlink:href="%23path-1"></use> </g> </g> </svg>`,
  };
}

function getContentString(info) {
  return `
  <div class="map-infoWindow">
  <h3>${info.name}</h3>

  <p class="location text-lite">
  ${info.location} &mdash; ${info.date}
  </p>

  <p class="text-lite">
   ${info.talks.join("<br>")}
  </p>

  </div>
  `;
}

function createMarker(map, info) {
  var contentString = getContentString(info);

  const markerDefaultOpacity = 0.6;

  var marker = new google.maps.Marker({
    position: { lat: info.lat, lng: info.lng },
    map: map,
    icon: getIcon(),
    opacity: markerDefaultOpacity,
  });

  var infowindow = new google.maps.InfoWindow({
    content: contentString,
    pixelOffset: new google.maps.Size(200, 100),
  });

  marker.addListener("mouseover", function () {
    marker.setOpacity(1);
    infowindow.open(map, marker);
  });
  marker.addListener("mouseout", function () {
    marker.setOpacity(markerDefaultOpacity);
    infowindow.close(map, marker);
  });
}

window.initMap = function () {
  var map = new google.maps.Map(document.getElementById("map"), {
    zoom: 3.4,
    center: {
      lat: 54,
      lng: 10,
    },
    styles: getStyles(),
    scrollwheel: false,
    streetViewControl: false,
    mapTypeControl: false,
  });

  var conferences = [
    {
      lat: 42.4618,
      lng: 14.2161,
      name: "GDG Pescara",
      location: "Pescara, Italy",
      date: "Nov 2019",
      talks: ["Secrets of native-like PWAs"],
    },
    {
      lat: 37.406426,
      lng: -122.021396,
      name: "Google Developer Experts Summit",
      location: "California, USA",
      date: "Oct 2019",
      talks: [
        "<strong>Lightning talk</strong>: What I learned from teaching programming",
      ],
    },
    {
      lat: 50.6292,
      lng: 3.0573,
      name: "Take Off Conf",
      location: "Lille, France",
      date: "Jun 2018",
      talks: ["The (Awesome) Future of Web Apps"],
    },
    {
      lat: 59.913911,
      lng: 10.752274,
      name: "NDC Oslo & Mobile Era",
      location: "Oslo, Norway",
      date: "Jun 2016, Jun 2017, Nov 2018 & Nov 2019",
      talks: [
        "Secrets of native-like PWAs",
        "Web performance in 2018",
        "The (Awesome) Future of Web Apps",
        "Scaling large AngularJS apps",
      ],
    },
    {
      lat: 49.452102,
      lng: 11.076665,
      name: "Developer Week",
      location: "Nürnberg, Germany",
      date: "Jun 2017",
      talks: ["The (Awesome) Future of Web Apps"],
    },
    {
      lat: 52.520035,
      lng: 13.404702,
      name: "CodeMotion, Google Berlin",
      location: "Berlin, Germany",
      date: "Nov 2019, Jan 2023",
      talks: ["Secrets of native-like PWAs", "How z-index actually works"],
    },
    {
      lat: 52.233564,
      lng: 21.001672,
      name: "Google Poland",
      location: "Warsaw, Poland",
      date: "Jun 2019",
      talks: ["Intro to Service Workers & Workbox"],
    },
    {
      lat: 52.378393,
      lng: 4.900254,
      name: "Fronteers, Microsoft, Abn Amro & others",
      location: "Amsterdam, Netherlands",
      date: "2015 - 2023",
      talks: [
        "Secrets of native-like PWAs",
        "The (Awesome) Future of Web Apps",
        "PWA Workshop",
        "Blazing fast CSS3 transitions",
        "Building progressive web apps with Angular",
      ],
    },
    {
      lat: 51.443267,
      lng: 5.47485,
      name: "Frontend love",
      location: "Eindhoven, Netherlands",
      date: "Nov 2019",
      talks: ["Native-like PWAs livecode - closing keynote"],
    },
    {
      lat: -33.86882,
      lng: 151.209296,
      name: "NDC Sydney",
      location: "Sydney, Australia",
      date: "Aug 2016 & Aug 2017",
      talks: [
        "The (Awesome) Future of Web Apps",
        "UX Patterns for web developers",
        "Blazing fast CSS3 transitions",
      ],
    },
    {
      lat: 40.416775,
      lng: -2.70379,
      name: "Madrid JS & JsDay ES",
      location: "Madrid, Spain",
      date: "Nov 2016, May 2017",
      talks: [
        "UX patterns for web developers",
        "The (Awesome) Future of Web Apps",
      ],
    },
    {
      lat: 44.426767,
      lng: 26.102538,
      name: "Bucharest JS",
      location: "Bucharest, Romania",
      date: "February, 2017",
      talks: ["The (Awesome) Future of Web Apps"],
    },
    {
      lat: 51.507448,
      lng: -0.127753,
      name: "FullStack London & NDC London",
      location: "London, United Kingdom",
      date: "Jul 2016, Jan 2017",
      talks: [
        "The (Awesome) Future of Web Apps",
        "UX Patterns for developers",
        "Blazing fast CSS3 transitions",
        "Introduction to AngularJS using ES2015",
      ],
    },
    {
      lat: 41.385064,
      lng: 2.173403,
      name: "AngularBeers Cinema",
      location: "Barcelona, Spain",
      date: "Dec 2016",
      talks: ["Building progressive web apps with Angular"],
    },
    {
      lat: 39.469907,
      lng: -0.376288,
      name: "Valencia JS",
      location: "Valencia, Spain",
      date: "Dec 2016",
      talks: ["The (Awesome) Future of Web Apps"],
    },
    {
      lat: 45.438384,
      lng: 10.991622,
      name: "JS Day",
      location: "Verona, Italy",
      date: "May 2016",
      talks: ["Blazing fast CSS3 transitions"],
    },
    {
      lat: 33.748995,
      lng: -84.387982,
      name: "Connect.Tech",
      location: "Atlanta, USA",
      date: "Oct 2016, Oct 2017",
      talks: [
        "PWA Workshop",
        "Modern Web APIs for a better UX",
        "UX Patterns for developers",
        "Scaling Large AngularJS Apps",
        "Building a PWA with Angular",
      ],
    },
    {
      lat: 42.697708,
      lng: 23.321868,
      name: "Bulgaria PHP",
      location: "Sofia, Bulgaria",
      date: "Oct 2016",
      talks: ["Blazing fast CSS3 transitions"],
    },
    {
      lat: 38.722252,
      lng: -9.139337,
      name: "Le Wagon Lisbon",
      location: "Lisbon, Portugal",
      date: "July, 2016",
      talks: ["JavaScript workshop"],
    },
    {
      lat: 51.219448,
      lng: 4.402464,
      name: "PHP Benelux",
      location: "Antwerp, Belgium",
      date: "January, 2016",
      talks: ["Introduction to Angular using EcmaScript 6"],
    },
    {
      lat: 42.360082,
      lng: -71.05888,
      name: "Northeast PHP",
      location: "Boston, USA",
      date: "August, 2015",
      talks: ["Kickstarting a Laravel app"],
    },
    {
      lat: 49.872906,
      lng: 8.658933,
      name: "Enter JS",
      location: "Darmstadt, Germany",
      date: "June, 2017",
      talks: [
        "Web APIs you must know about in 2017",
        "UX patterns for web developers",
      ],
    },
    {
      lat: 63.43066,
      lng: 10.395038,
      name: "Webstep & GDG Trondheim",
      location: "Trondheim, Norway",
      date: "November, 2018",
      talks: ["PWA Workshop", "Web performance in 2018"],
    },
  ];

  conferences.forEach(function (conference) {
    createMarker(map, conference);
  });
};

function getStyles() {
  return [
    {
      featureType: "all",
      elementType: "labels.text.fill",
      stylers: [
        {
          visibility: "off",
          saturation: 36,
        },
        {
          color: "#000000",
        },
        {
          lightness: 40,
        },
      ],
    },
    {
      featureType: "all",
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "off",
          visibility: "on",
        },
        {
          color: "#000000",
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: "all",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 17,
        },
        {
          weight: 1.2,
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.country",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "administrative.country",
      elementType: "geometry",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "administrative.country",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.locality",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified",
        },
        {
          saturation: "-100",
        },
        {
          lightness: "30",
        },
      ],
    },
    {
      featureType: "administrative.neighborhood",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified",
        },
        {
          gamma: "0.00",
        },
        {
          lightness: "74",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [
        {
          color: "#181e21",
        },
        {
          lightness: 10,
        },
      ],
    },
    {
      featureType: "landscape.man_made",
      elementType: "all",
      stylers: [
        {
          lightness: "3",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 21,
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 17,
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 29,
        },
        {
          weight: 0.2,
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 18,
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000",
        },
        {
          lightness: 19,
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#222c33",
        },
        {
          lightness: 0,
        },
      ],
    },
  ];
}

/* Light YouTube Embeds by @labnol */
/* Web: http://labnol.org/?p=27941 */

document.addEventListener("DOMContentLoaded", function () {
  var div,
    n,
    v = document.getElementsByClassName("youtube-player");
  for (n = 0; n < v.length; n++) {
    div = document.createElement("div");
    div.setAttribute("data-id", v[n].dataset.id);
    div.innerHTML = labnolThumb(v[n].dataset.id);
    div.onclick = labnolIframe;
    v[n].appendChild(div);
  }
});

function labnolThumb(id) {
  var thumb = '<img src="https://i.ytimg.com/vi/ID/hqdefault.jpg" alt="">',
    play = '<div class="play"></div>';
  return thumb.replace("ID", id) + play;
}

function labnolIframe() {
  var iframe = document.createElement("iframe");
  var embed =
    "https://www.youtube.com/embed/ID?rel=0&cc_load_policy=1&cc_lang_pref=en&autoplay=1";
  iframe.setAttribute("width", "500");
  iframe.setAttribute("src", embed.replace("ID", this.dataset.id));
  iframe.setAttribute("frameborder", "0");
  iframe.setAttribute("allowfullscreen", "1");
  this.parentNode.replaceChild(iframe, this);
}

// // // document.querySelector("#menu-contact").addEventListener("click", event => {
// // //   event.preventDefault();
// //   document.querySelector("#contact").scrollIntoView({ behavior: "smooth" });
// });

let videoSrc = null;

if (screen.width < 300) {
  //no video on very small phones
} else if (screen.width < 800) {
  videoSrc =
    "https://res.cloudinary.com/dbfn5lnvx/video/upload/q_60,c_scale,g_center,w_650/v1562663856/jadjoubran.io/jadjoubran2020.mp4";
} else {
  videoSrc =
    "https://res.cloudinary.com/dbfn5lnvx/video/upload/q_60/v1562663856/jadjoubran.io/jadjoubran2020.mp4";
}
if (videoSrc) {
  videoTag = `<video preload="auto"width="100%" height="100%" autoplay muted loop playsinline src="${videoSrc}"/>`;
  document.getElementById("video-wrapper").innerHTML = videoTag;
}
